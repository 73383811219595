(function ($) {
  "use strict";
  /*---- Variables ------------------------------*/
  var $window = $(window);
  var $navburger = $('#js-navburger');
  var $menu = $('#js-menu');
  var $submenuItem = $menu.find('.has-submenu');
  var $submenuTrigger = $menu.find('.has-submenu > a');

  /*---- Functions ------------------------------*/
  function clickMenu (width) {
    if(width <= 768) {
      $navburger.bind('click', function () {
        $(this).toggleClass('is-active');
        $menu.toggleClass('is-visible');
      });
      $submenuTrigger.bind('click', function (e) {
        e.preventDefault();
        $submenuItem.toggleClass('is-open');
      });
    } else {
      $navburger.unbind('click');
      $submenuItem.unbind('click');
    }
  }

  /*---- Events ------------------------------*/

  $window.resize(function () {
    clickMenu($(this).width());
  });

  $window.on('load', function () {
    clickMenu($(this).width());
  });


})(jQuery);